.senior-container{
width: 70%;
overflow-y: auto; /* ou overflow-y: scroll; */
  height: 100vh;
margin-left: 25%;
margin-top: -35%;
border-top: 1px solid rgb(202,107,69) ;
border-left: 1px solid rgb(202,107,69);
    img{width: 100%;
     }
     .img2{width: 100%;}
     .img3{width: 100%;}
   /* Style pour la barre de défilement elle-même */

}
.senior-container::-webkit-scrollbar {
  width: 12px; 
  
}

/* Style pour la poignée du scrollbar (la partie que je fais glisser) */
.senior-container::-webkit-scrollbar-thumb {
  background-color: rgb(202,107,69); /* Couleur de la poignée */
  border-radius: 6px; 
}

/* Style pour la piste du scrollbar (la partie vide où la poignée se déplace) */
.senior-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Style pour les coins du scrollbar */
.senior-container::-webkit-scrollbar-corner {
  background-color: #f1f1f1; 
}  
.container-footer {
    
    position: absolute;
  }

  @media only screen and (max-width: 480px) {
    .senior-container{
      margin-left: 0%;
      margin-top: 10px;
      width: 100%;
  }
}

@media only screen and (max-width: 820px) and (min-width: 481px){

  .senior-container{
    width: 100%;
    margin: 0;
    margin-top: -0px;
   
   }
}

