@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap");

.user-profile {
  // background: #FFFff;

  .choix {
    width: 100%;
    height: auto;
  }
   
  .container-profil {
    // display: flex;
    width: 20%;
    height: auto;
    margin-top: 10%;
    justify-content: center;
    margin-left: 2%;
    // background: rgba(226,226,226,1);
    .profile-content {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
      border: #374151 solid 1px;
      border-radius: 15px;
      margin-top: -100px;
    }
    .btn-container {
      .accueil {
        width: 50%;
        background-color: #dfdfe0;
        margin: 5px;
        padding: 10px;
        border-radius: 50%;
      }
      .cours {
        width: 50%;
        background-color: #dfdfe0;
        margin: 5px;
        padding: 10px;
        border-radius: 50%;
      }
      .senior {
        width: 50%;
        background-color: #dfdfe0;
        margin: 5px;
        padding: 10px;
        border-radius: 50%;
      }
      .jeux {
        width: 50%;
        background-color: #dfdfe0;
        margin: 5px;
        padding: 10px;
        border-radius: 50%;
      }
      .taches {
        width: 50%;
        background-color: #dfdfe0;
        margin: 5px;
        padding: 10px;
        border-radius: 50%;
      }
      .sociaux {
        width: 50%;
        background-color: #dfdfe0;
        margin: 5px;
        padding: 10px;
        border-radius: 50%;
      }
      .discord {
        width: 50%;
        background-color: #dfdfe0;
        margin: 5px;
        padding: 10px;
        border-radius: 50%;
      }
      .profil {
        width: 50%;
        background-color: #dfdfe0;
        margin: 5px;
        padding: 10px;
        border-radius: 50%;
      }
      .changer {
        width: 50%;
        background-color: #dfdfe0;
        margin: 5px;
        padding: 10px;
        border-radius: 50%;
      }
      .deconnecter {
        width: 45%;
        background-color: #dfdfe0;
        margin: 5px;
        padding: 10px;
        border-radius: 50%;
      }
      .fermer {
        width: 50%;
        background-color: #dfdfe0;
        margin: 5px;
        padding: 10px;
        border-radius: 50%;
      }
      .generateur {
        width: 50%;
        background-color: #dfdfe0;
        margin: 5px;
        padding: 10px;
        border-radius: 50%;
      }
      .btn {
        margin-bottom: 10px;
        border-radius: 100px;
        background-color: #dfdfe0;
        margin: 5px;
        // background-color: rgb(202,107,69);
      }
      .btn:hover {
        border-top-right-radius: 0%;

        font-weight: bold;
        //  background-color: rgb(202,107,69);
      }
      button {
        // background-color: rgb(202,107,69);
        width: 160px;
        height: auto;
        border-radius: 100px;
        font-size: 15px;
        margin-bottom: 10px;
        cursor: pointer;
        z-index: -1000;
      }
      button:hover {
        // background-color: rgb(202,107,69);
        color: white;
      }
    }

    .btn-containerA {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 10px;
      z-index: -1000;
      width: 100%; /* Ajustement de la largeur pour les écrans étroits */
      margin-left: 0;
      .accueil {
        width: 30%;
        background-color: #dfdfe0;
        border-radius: 50%;
       
      }
      .cours {
        width: 30%;
        background-color: #dfdfe0;
        border-radius: 50%;
      }
      .senior {
        width: 30%;
        background-color: #dfdfe0;
        border-radius: 50%;
      }
      .jeux {
        width: 30%;
        background-color: #dfdfe0;
        border-radius: 50%;
      }
      .taches {
        width: 30%;
        background-color: #dfdfe0;
        border-radius: 50%;
      }
      .sociaux {
        width: 30%;
        background-color: #dfdfe0;
        border-radius: 50%;
      }
      .discord {
        width: 30%;
        background-color: #dfdfe0;
        border-radius: 50%;
      }
      .profil {
        width: 30%;
        background-color: #dfdfe0;
        border-radius: 50%;
      }
      .changer {
        width: 30%;
        background-color: #dfdfe0;
        border-radius: 50%;
      }
      .deconnecter {
        width: 30%;
        background-color: #dfdfe0;
        border-radius: 50%;
      }
      .fermer {
        width: 30%;
        background-color: #dfdfe0;
        border-radius: 50%;
      }
      .generateur {
        width: 30%;
        background-color: #dfdfe0;
        border-radius: 50%;
      }
      button {
        // background-color: rgb(202,107,69);
        width: 100%;
        height: auto;
        border-radius: 100px;
        font-size: 15px;
        margin-bottom: 10px;
        cursor: pointer;
        z-index: 100;
      }
      .reseau-sociaux-menu {
        // background-color: rgb(202,107,69);
        .btnReseaux {
          margin-left: -0px;
          margin-top: 5px;
        }
      }
      .reseau-sociaux-button {
        border: 01px solid #dfdfe0;
        background-color: #dfdfe0;
        color: 000;
      }
      .reseau-sociaux-button:hover {
        // background-color: rgb(202,107,69);
        color: white;
      }
      .ligne {
        border-top: 2px solid rgba(128, 128, 128, 0.5);
        width: 85%;
        padding-bottom: 10px;
      }
      .reseau-sociaux-menu {
        // background-color: rgba(202, 107, 69, 0.498);
        border: 5px solid white;
        width: 100%;
      }
      .jeux-button {
        border: 1px solid #dfdfe0;
        background-color: #dfdfe0;
        color: 000;
      }
      .jeux-button:hover {
        // background-color: rgb(202,107,69);
        color: white;
      }

      .jeux-menu {
        // background-color: rgba(202, 107, 69, 0.498);
        border: 5px solid white;
        .btnJeu {
          margin-top: 5px;
          margin-left: 0;
        }
      }

      .btn {
        position: relative;
        width: 160px;
        height: auto;
        border-radius: 100px;
        font-size: 15px;
        margin-bottom: 10px;
        cursor: pointer;
        background-color: #dfdfe0;
      }

      .arrow {
        display: inline-block;
        position: absolute;
        top: -50%;
        transform: translateY(-50%);
        right: -35px;
      }

      .hovered {
        border: 2px solid rgb(202, 107, 69);
        /* Ajoutez un style de fond pour indiquer que le bouton est survolé */
        color: #fff; /* Ajoutez un style de couleur de texte pour améliorer la lisibilité */
      }

      .arrow::before {
        content: "";
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 15px solid rgba(202, 107, 69, 0.498); /* Couleur de la flèche (bleu dans cet exemple) */
      }
    }
    span {
      margin-right: 8px;
      .spamb {
        color: rgb(142, 46, 1);
        margin-left: -20px;
      }
    }

    .profile_img {
      margin-left: 15%;
      border-radius: 50%;
      text-align: center;
      position: relative;

      cursor: pointer;

      .profile-image {
        width: 200px; /* Définissez la largeur désirée pour toutes les photos de profil */
        height: 200px; /* Définissez la hauteur désirée pour toutes les photos de profil */
       
        border: 3px solid white;
      }
      img {
        width: 100%;
        height: auto;
      }
      img:hover {
        transform: rotateY(180deg);
      }
    }
    .container-avatar {
      overflow-y: hidden;
    }

    .data-profil {
      // border: 5px solid rgba(202, 107, 69, 0.483) ;
      // display: flex;
      // flex-direction: column;
      // background-color:white;
      // // background: url("../media/bandeauYoutube.jpg") no-repeat;
      // background-size: 150%;
      // margin-bottom: 10px;

      position: absolute;
      top: 0;
      left: 0;
      width: 90%; /* Largeur du dégradé */
      height: 30%; /* Hauteur du dégradé */
      margin-top: 170px;
      margin-left: 5%;
      background: linear-gradient(
        45deg,
        #ff8a00,
        #e52e71
      ); /* Couleurs du dégradé */
      
      z-index: -1;

      p {
        color: #060606;
        margin-left: 90%;
        font-weight: 700;
        margin-right: 0;
        display: flex;
        font-size: 1rem;
        font-family: alto, sans-serif;
        text-align: justify;
        justify-content: space-between;
        margin-bottom: 10px;
      }
    }
    //  .data-profil::after{
    //     content: '';
    //     border: 5px solid rgba(202, 107, 69, 0.483) ;
    //     display: flex;
    //     flex-direction: column;
    //     position: absolute;
    //     // width: 18%;
    //     // height: 13%;
    //       // background: url("../media/bandeauYoutube.jpg") no-repeat;

    //     //   background-size: 130%;
    //     //  opacity: 0.3;
    //   }
  }
}

// /* Media queries pour les écrans de taille moyenne (tablette) */
@media screen and (min-width: 568px) and (max-width: 780px) {
  /* Styles spécifiques pour tablette */
  .right-section {
    margin-left: 0; /* Ajustez la marge pour tablette */
  }

  .container-profil {
    width: 100%;
    .user-profile {
      margin-left: 15px; /* Ajustez la marge pour tablette */
    }

    .profile-container {
      .select-page {
        width: 100%; /* Ajustez la largeur à 100% pour tablette */
      }
    }

    .profile_img {
      .profile-image {
        margin-right: 0; /* Ajustez la marge pour tablette */
      }
    }

    .data-profil {
      display: none;
      p {
        span {
          display: block; /* Affichez les spans en bloc pour empiler sur tablette */
          margin-left: 0; /* Ajustez la marge pour tablette */
        }
      }
      background: none;
    }
    .data-profil::after {
      display: none;
     
    }
  }
}

// // /* Media queries pour les écrans de petite taille (smartphone) */
@media screen and (max-width: 567px) {
  /* Styles spécifiques pour smartphone */
  .container-profil {
    width: 100%;
    .user-profile {
      margin-left: 10px; /* Ajustez la marge pour smartphone */
    }

    .profile-container {
      margin-top: 100px;
      .select-page {
        width: 100%; /* Ajustez la largeur à 100% pour smartphone */
      }
    }

    .profile_img {
      .profile-image {
        margin-right: 0; /* Ajustez la marge pour smartphone */
      }
    }

    .data-profil {
      display: none;
      p {
        span {
          display: block; /* Affichez les spans en bloc pour empiler sur smartphone */
          margin-left: 0; /* Ajustez la marge pour smartphone */
        }
      }
    }
    .data-profil::after {
      display: none;
    }
  }
}
// // /* Ajout de media query pour les petits écrans (par exemple, les téléphones) */
@media only screen and (max-width: 480px) {
  .user-profile {
    width: 100%;

    .container-profil {
      width: 100%;
      margin-left: -1px;
      .data-profile {
        p {
          span {
            /* Ajustez la marge pour les petits écrans */
            text-align: right; /* Alignez le texte à droite pour les petits écrans */
          }
        }

        .spamb {
          margin-left: 0; /* Ajustez la marge pour les petits écrans */
        }
      }
      .data-profil::after {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 834px) and (min-width: 481px) {
  .user-profile {
    width: 100%;
    #news-ticker {
      position: relative;
      z-index: 0;
      margin-top: 50px;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      border: 2px solid white;
      box-shadow: -5px 5px 15px rgba(0, 0, 0, 0.3);
      background-color: #dfdfe0;
      #ticker-content {
        display: inline-flex;
        list-style-type: none;
        padding: 0;
        background-color: #fff;
        font-size: 24px;
        animation: ticker linear infinite;
        animation-duration: 300s;
        animation-fill-mode: backwards;
        animation-play-state: running;
      }
    }
    .container-profil {
      width: 100%;
      margin-top: 120px;
      margin-left: -2px;
      .data-profile {
        p {
          span {
            margin-left: 0; /* Ajustez la marge pour les petits écrans */
            text-align: right; /* Alignez le texte à droite pour les petits écrans */
          }
        }

        .spamb {
          margin-left: 0; /* Ajustez la marge pour les petits écrans */
        }
      }
      .data-profil::after {
        display: none;
      }
    }
  }
}
