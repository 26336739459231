.container-service {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    border:#374151 solid 1px;
    border-radius: 15px;
    margin-top: 67px;
    margin-bottom: 67px;
    padding: 50px;
font-size: 22px;
    h2 {text-align: center;}
ul {
    list-style-type: none;
}
    img {
        width: 31px;
    }

    img:hover {
        background-color: #37415166;
        border-radius: 5px;
    }
    
p, h2  {
    font-family: 'spartan_mbsemibold', sans-serif; 
    color: #374151;
    font-size: 22px;
}
h3 {
    color: rgba(1, 128, 142, 0.953);
}
}

@media screen and (min-width: 279px) and (max-width: 567px) {
    .container-service{
        width: 72%;
        margin-top: -3px;
        margin-right: 12px;

}
}