.modif-container {
  display: flex;
  margin-left: 25%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
  width: 50%;
  height: auto;
  margin-top: -55%;
  margin-bottom: 5%;
  border: #374151 solid 1px;
  border-radius: 15px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
  .edit-profile {
    margin-bottom: 25px;
    margin-left: 15px;
    margin-right: 15px;
    text-align: start;
    input {
      background-color: beige;
      border-radius: 2%;
      margin-left: 50%;
    }
    .enregistrer {
      margin-top: 20px;
    }
  }
}

// // /* Media queries pour les écrans de taille moyenne (tablette) */
@media screen and (min-width: 568px) and (max-width: 780px) {
  .modif-container {
    width: 100%;
    margin: 0;
    .edit-profile {
      width: 90%;
      margin-top: 10px;
      margin-left: 30px;
      .arrow-container {
        display: none;
      }
      .containeredit {
        .form-group {
          input {
            width: 130px;
          }
        }
      }
    }
    .arrow-container1 {
      display: none;
    }
  }
}
// // /* Media queries pour les écrans de petite taille (smartphone) */
@media screen and (max-width: 567px) {
  .modif-container {
    width: 100%;

    margin: 0px;
    .edit-profile {
      width: 90%;
      margin-left: 20px;
      .arrow-container {
        display: none;
      }

      .form-group {
        input {
          width: 130px;
        }
      }
    }
    .arrow-container1 {
      display: none;
    }
  }
}
@media only screen and (max-width: 820px) and (min-width: 481px) {
  .modif-container {
    width: 100%;
    margin: 0;
    margin-top: 40px;
  }
}
