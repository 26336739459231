.hangmangame-container {
  background-color: #FFF;
  margin-left: 25%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
  width: 70%;
  height: auto;
  margin-top: -35%;
  margin-bottom: 5%;
  border: #374151 solid 1px;
  border-radius: 15px;
  text-align: center;
  align-items: center;
  justify-content: center;
  button {
    background-color: rgba(202, 107, 69, 0.493);
    border: 1px solid rgba(202, 107, 69, 0.997);
    margin-bottom: 10px;
    border-radius: 5%;
  }
  span {
    margin-left: 3px;
  }

  .hangmangame-content {
    .head {
      width: 6%;
      height: 60px;
      background-color: #ffcccb; /* Couleur de la tête */
      border-radius: 50%;
      margin: 20px auto;
      margin-left: 47%;
      margin-top: 10px;
      border: 2px solid black;
    }

    .body {
      width: 1.5%;
      height: 110px;
      background-color: #b0e0e6; /* Couleur du corps */
      margin: 0 auto;
      margin-left: 49.5%;
      margin-top: 10px;
      border: 2px solid black;
    }

    .left-arm,
    .right-arm {
      width: 6%;
      height: 6%;
      background-color: #b0e0e6; /* Couleur des bras */
      border: 2px solid black;
    }

    .left-arm {
      transform: rotate(45deg);
      left: 44%; /* Ajustement pour le bras gauche */
      border: 2px solid black;
      position: relative;
      top: -130px;
    }

    .right-arm {
      transform: rotate(-45deg);
      left: 50.5%; /* Ajustement pour le bras droit */
      border: 2px solid black;
      position: relative;
      top: -130px;
    }

    .left-leg,
    .right-leg {
      width: 2%;
      height: 70px;
      background-color: #b0e0e6; /* Couleur des jambes */

      border: 2px solid black;
    }

    .left-leg {
      transform: rotate(45deg);
      right: -45%;
      position: relative;
      border: 2px solid black;
      margin-top: -30px;
    }

    .right-leg {
      transform: rotate(-45deg);
      left: 54%; /* Ajustement pour la jambe droite */
      margin-top: -70px;
      border: 2px solid black;
      position: relative;
    }
    .alphabet {
      margin-top: 60px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .hangmangame-container {
    width: 100%;
    margin: 0;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 835px) and (min-width: 481px) {
  .hangmangame-container {
    width: 100%;
    margin: 0;
    margin-top: -0px;
    .game-board {
      width: 100%;
      .square {
        width: 100%;
        margin-left: -53%;
      }
    }
  }
}
