.composition-container {
  font-family: "spartan_mbsemibold", sans-serif;
  p {
    text-align: justify;
    font-size: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  img {
    width: 40%;
    border: solid 5px white;
}
}
