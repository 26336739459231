.container-tarif {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  border: #374151 solid 1px;
  border-radius: 15px;
  margin-top: 9%;
  margin-bottom: 8%;
  padding: 50px;
  font-size: 22px;
  h2 {
    text-align: center;
  }
  ul {
    list-style-type: none;
  }

  p,
  h2 {
    font-family: "spartan_mbsemibold", sans-serif;
    color: #374151;
    font-size: 22px;
  }
}

@media only screen and (max-width: 480px) {
  .container-tarif {
    width: 100%;
  }
}