.periph-container{
    .imprimante{
        width: 80%
    }
img {
    width: 40%;
    border: solid 5px white;
}
    h2 {
        margin-left: 10px;
        margin-right: 10px;
    }
    p{font-size: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }
}