.ticTac-container{
    margin-top: -35%;
    background: rgba(226, 226, 226, 1);
    margin-left: 25%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
    width: 70%;
    height: auto;
    margin-bottom: 5%;
    border: #374151 solid 1px;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    text-align: center;


.game-board {
    display: flex;
    flex-wrap: wrap;
    width: 70%; /* Taille totale de la grille (3 cases * 100px + 6px de bordure) */
    margin-left: 18%;
    margin-right: 0;
    align-content: center;
    justify-content: space-around;
    align-content: space-around;
  
  
  .row {
    display: flex; 
  }
  
  .square {
    width: 200px;
    height: 200px;
    font-size: 32px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 3px solid #000; /* Bordure des cases */
    background-color: #fff; /* Couleur de fond des cases */
    cursor: pointer; /* Curseur de souris pointer */
    box-sizing: border-box; /* Inclure la bordure dans la taille totale des cases */
  }
  
  .square:hover {
    background-color: #f0f0f0; /* Couleur de fond des cases au survol */
  }
  .circle {
    color: blue; /* Couleur des ronds */
  }
  
  .cross {
    color: red; /* Couleur des croix */
  }
  
  .board-row {
    width: 33.33%;
    height: auto;
    
  }}
  button{
    background-color: rgba(202, 107, 69, 0.493);
    border: 1px solid rgba(202, 107, 69, 0.997);
    margin-bottom: 10px;
    border-radius: 5%;
  }
}

@media only screen and (max-width: 480px) {
  .ticTac-container{
     width: 100%;
     margin: 0;
     margin-top: 10px;
    .game-board{
      width: 100%;
      .square{
        width: 100%;
        margin-left: -53%;
      }
      }
    }
  }

  @media only screen and (max-width: 820px) and (min-width: 481px){

    .ticTac-container{
      width: 100%;
      margin: 0;
      margin-top: -0px;
     .game-board{
       width: 100%;
       .square{
         width: 100%;
         margin-left: -53%;
       }
       }
     }
  }

