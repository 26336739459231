@import url("https://fonts.googleapis.com/css2?family=Tapestry&display=swap");

.todolist {
  display: flex;
  width: 70%;
  height: 830px;
  margin-top: -35%;
  margin-left: 25%;
  background-image: url(../media/liste.jpg);
  background-size: cover;
  background-position: center;

  .container-list {
    h1 {
      margin-top: 100px;
      text-transform: uppercase;
      font-size: 3em;

      margin-right: 700px;
      margin-left: 60px;
      font-family: "Tapestry", cursive;
      text-shadow: maroon 2px 2px 2px;
      transform: rotate(-0deg);
    }
    form {
     
      margin-top: 200px;
      justify-content: left;
      align-items: center;
      margin-left: 2%;
      border-radius: 5px;
      input {
        padding: 20px;
        border-radius: 50px;
      }

      button {
        color: chocolate;
        background: white;
        cursor: pointer;
        transition: all 0.3s ease;
        border-radius: 50px;
      }
      button:hover {
        background: chocolate;
        color: coral;
      }

      select {
        -webkit-appearance: none;
        -moz-appeareance: none;
        appearance: none;
        outline: none;
        box-shadow: none;
        border: none;
        border-radius: 5px;
      }

      .select1 {
         margin: 1rem;
        position: relative;
        overflow: hidden;
        color: chocolate;
        width: 7rem;
        cursor: pointer;
        border: 1px solid black;
        text-align: center;
        // padding: 1rem;
      }

      .select1::after {
        content: "\25BC";
        position: absolute;
        background: chocolate;
        top: 0;
        right: 0;
        padding: 1rem;
        pointer-events: none;
        transition: all 0.3s ease;
      }

      .select1:hover::after {
        background: white;
        color: chocolate;
      }
      .select2 {
         margin: 1rem;
        position: relative;
        overflow: hidden;
        color: chocolate;
        width: 7rem;
        cursor: pointer;
        border: 1px solid black;
        text-align: center;
        // padding: 1rem;
      }

      .select2::after {
        content: "\25BC";
        position: absolute;
        background: chocolate;
        top: 0;
        right: 0;
        padding: 1rem;
        pointer-events: none;
        transition: all 0.3s ease;
      }

      .select2:hover::after {
        background: white;
        color: chocolate;
      }

      
    }
    .filter-todo {
      width: 100px;
      border: 1px gray solid;
      border-radius: 25px;
      text-align: center;
    }
    .todo-container {
      
      justify-content: center;
      align-items: center;
      margin-left: 5%;
      margin-top: 50px;
     
    }
  }
}
@media only screen and (max-width: 600px) {
  .todolist {
    .container-list {
      width: 100%;
      margin: 0;
      h1 {
        font-size: 0.5em;
        text-align: center;
      }

      form {
      }

      input {
        width: 12%;
      }

      // body {
      //   display: flex;
      //   background: url(../media/liste.jpg);
      //   background-size: 150%;
      // }
    }
  }
}
@media only screen and (max-width: 768px) {
  h1 {
    font-size: 2em;
    text-align: center;
  }

  // body {
  //   background: url(../media/liste.jpg);
  //   background-size: 150%;
  // }
}

@media only screen and (max-width: 480px) {
  .todolist {
    width: 100%;
    height: 350px;
    margin: 0;
    margin-top: 10px;
    display: flex;
    background: url(../media/liste.jpg);
    background-size: 150% ;
    
    .container-list {
      h1 {
        margin-top: 5px;
        font-size: 1.5em;
        text-align: center;
      }

      form {
        margin-top: 5px;
        input {
          width: auto;
        }
      }
    }
  }
}
@media only screen and (max-width: 820px) and (min-width: 481px){

  .todolist{
    width: 100%;
    margin: 0;
    margin-top: -0px;
   
   }
}