.container-quizA{
   background-color: lavender;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
    width: 70%;
    height: auto;
    margin-bottom: 5%;
    justify-content: center;
    border: #374151 solid 1px;
    border-radius: 15px;
    margin-left: 15%;
    margin-top: 5%;
    text-align: center;
    .question-quizA{
        display: column;
        ul{list-style:  none;
        li{padding: 10px;
        input{margin-right: 10px;}}
    }
    }
    
}
.arrow-container {
        display: inline-block;
        position: absolute;
        top: -100px;
        transform: translateY(-50%);
        left: 980px;
      }
