.dictionnaire-container{
    background-color: rgb(232, 232, 232);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
    height: auto;
    border: #374151 solid 1px;
    border-radius: 15px;
   .dictionnaire-content{ p{
    font-size: 20px;
    text-align: justify;
    margin-left: 10px;
    margin-right: 10px;
   }}
}