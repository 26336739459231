.securite-content{
    text-align: justify;
    margin-left: 10px;
    margin-right: 10px;
    img{
    width:20%;
    }
    .avg{
        width: 20%;
    }
    .avira{
        width: 15%;
    }
    .bitdefender{
    width:30%;
    }
    .sophos{
        width: 30%;
    }
    p{
        font-size: 18px;
    }
}