.chat-window {
    position: relative; /* Permet d'utiliser position:absolute dans les enfants */
    height: 100vh; /* Ajustez la hauteur en fonction de vos besoins */
    display: flex;
    flex-direction: column;
  }

  /* Fenêtre où les messages sont affichés */
.message-window {
    flex: 1; /* La fenêtre de messages prendra toute la hauteur restante */
    overflow-y: auto; /* Activation du défilement vertical si nécessaire */
    position: relative; /* Permet de positionner les messages absolument */
  }
  
  /* Style pour chaque message dans la fenêtre */
  .message {
    position: relative;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f2f2f2;
    border-radius: 5px;
  }
  
  /* Style pour le texte des messages */
  .message-text {
    color: #333;
  }
  
  /* Zone de saisie de message et bouton pour envoyer un message */
  .message-input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #f2f2f2;
  }
  
  /* Zone de saisie de message */
  .message-input {
    flex: 1; /* La zone de saisie de message prendra autant d'espace que possible */
    margin-right: 10px; /* Espacement avec les boutons */
  }
  
  /* Bouton pour envoyer un message */
  .send-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Bouton pour afficher le sélecteur d'emojis */
  .emoji-button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px; /* Espacement avec le bouton pour démarrer la vidéo */
  }
  
  /* Bouton pour démarrer la vidéo */
  .video-button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Fenêtre modale pour les émojis */
  .emoji-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 999; /* Assure que la fenêtre modale est au-dessus de tout */
  }