.srcode-container {
  background-color: #FFFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
  width: 70%;
  height: auto;
  margin-bottom: 5%;
  justify-content: center;
  border: #374151 solid 1px;
  border-radius: 15px;
  margin-left: 25%;
  margin-top: -34%;
  text-align: center;
 li {list-style: none;} 
}

@media screen and (min-width: 279px) and (max-width: 567px) {
  .srcode-container {
    flex-direction: column;
    margin-top: 5%;
    width: 100%;
    margin: 0;
}
}