@import url("https://fonts.googleapis.com/css?family=Lato:300");

.container-reseaux {
     .facebook-content{
        background: rgba(226,226,226,1);;
    margin-left: 25%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
    width: 70%;
    height: auto;
    margin-top: -35%;
    margin-bottom: 5%;
    border: #374151 solid 1px;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
   img{
    border: 5px solid rgb(126, 124, 124);
    width: 50%;
   }
   .un{margin-left: 25%;
  }
  .une{margin-left: 25%;}
  .deux{margin-left: 25%;}
  .trois{margin-left: 25%;}
  .quatre{margin-left:25%}
  .cinq{margin-left: 25%;}
  .six{margin-left: 25%}
  .sept{margin-left: 25%}
  .huit{margin-left: 25%}
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}
font-size: 20px;
text-align: justify;
h2{text-align: center;margin-top: 170px;}
h3{margin: 10px;}
span{font-weight: bolder;
margin: 10px;}
p{margin: 20px;}

}
.logoFacebook img{
  width: 200px;
  height: auto;
  margin-left: 55%;
  margin-top: -60%;
 
}

@media only screen and (max-width: 480px) {
  .container-reseaux{
   width: 100%;
   
    .facebook-content{
    margin-top: -30px;
  margin-left: -0%;
   width: 100%;
 }
 .logoFacebook img{
  width: 100px;
  margin-top: 30px;
 }
 h2{text-align: center;margin-top: 70px;}
h3{margin: 10px;}
span{font-weight: bolder;
margin: 10px;}
p{margin: 20px;}

    }
  }

  @media only screen and (max-width: 820px) and (min-width: 481px){

    .container-reseaux{
      .facebook-content{width: 100%;
      margin: 0;
      margin-top: -100px;}
      .logoFacebook img{
        width: 200px;
        margin-top: 20px;
       }
     }

  }