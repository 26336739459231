.don-container {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  border: #374151 solid 1px;
  border-radius: 15px;
  margin-top: 67px;
  margin-bottom: 67px;
  padding: 50px;

  h1{text-align: center;
border: #374151 1px solid;}

  .don-content{
    text-align: justify;
    font-weight: 600;
  }

.paypal-content{
.paypal-image{display: flex;}}
}

@media only screen and (max-width: 820px) and (min-width: 481px){

  .don-container{
    width: 100%;
    margin: 0;
    margin-top: -0px;
z-index: -1000;
margin-top: 100px;
   }
}

@media only screen and (max-width: 480px) {
  .don-container{
      width: 72%;
      margin-top: 10%;
      margin-left: 0%;
     .paypal-image{
      display:flex;
      flex-direction: column;
      
     }
    }
  }

    