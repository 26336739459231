.sudoku {
  font-family: Arial, sans-serif;
  background: rgba(226, 226, 226, 1);
  margin-left: 25%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
  width: 70%;
  height: auto;
  margin-top: -35%;
  margin-bottom: 5%;
  border: #374151 solid 1px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
}

.sudoku-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  border: 2px solid black;
  border-collapse: collapse;
  margin: 0 auto;
  width: 30%;
}

.sudoku-row {
  display: flex;
}

.sudoku-cell {
  flex: 1;
  border: 1px solid black;

  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

@media only screen and (max-width: 480px) {
  .sudoku{
    width: 100%;
    margin: 0;
    margin-top: 10px;
    .sudoku-grid{
      width: 90%;
    }
  }

}

@media only screen and (max-width: 820px) and (min-width: 481px){

  .sudoku{
    width: 100%;
    margin: 0;
    margin-top: 40px;
    .sudoku-grid{
      width: 70%;
    }
   }

}