.fenetre-container{
    font-size: 20px;
    .bureau{width: 30%;}

    .taches{width: 100%;}

    .epingle{width: 100%;}

    .explorateur{ width: 100%;}

    .fenetreActive{width: 100%;}

    .barredetitre{ width: 90%;}
    
    .reduire{width: 90%;}

    .agrandir{width: 90%;}

    .close{width: 90%;}
    img{border: solid 5px white;}
}