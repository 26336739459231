body{font-family: 'spartan_mbsemibold', sans-serif;}
.container-connexion {

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
    width: 50%;
    margin-top: 6%;
    margin-bottom: 6%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    border:#374151 solid 1px;
    border-radius: 15px;
    text-align: center;
    

    h2 {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 10px;
        margin-top: 1%;
        font-family: 'spartan_mbsemibold', sans-serif; 
      }

      
        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 20px;
            input {
                width: 100%;
                height: 40px;
                margin-bottom: 20px;
                border-radius: 10px;
                border: 1px solid #374151;
                padding: 10px;
                font-size: 16px;
                color: #374151;
                font-family: 'spartan_mbsemibold', sans-ser
                if;
                }
                button {
                    width: 50%;
                    height: 40px;
                    margin-top: 20px;
                    border-radius: 10px;
                    border: 1px solid #374151;
                    padding: 10px;
                    font-size: 16px;
                    color: #374151;
                    font-family: 'spartan_mbsemibold', sans-ser
                    if;
                    
                    }
                    button:hover{
                        background-color: #d2d7df;
                    }
        }     
}

 @media only screen and (max-width: 480px) {
    .container-connexion{
        width: 100%;
    
    }
    .footer-container{
       
        margin-bottom: -0px;
    }
}