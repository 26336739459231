
.footer-container {
  margin-left: 0;
margin-top: 700px;
  z-index: -5;
  .footer-nav {
    // margin-top: 20px;
    background-color: rgb(202, 107, 69);
    border: 5px solid white;
    border-radius: 25px;
    padding: 10px;
    ul {
      list-style: none;
      display: flex;
      gap: 10px;
      padding: 0;
      margin: 0;
    }

    li {
      margin-right: 10px;
    }
    li:last-child {
      margin-right: 0;
      list-style-type: none;
    }
    a {
      text-decoration: none;
      color: rgb(250, 250, 250);
    }

    .logo-letter {
      font-size: 24px;
      font-weight: bold;
    }
  }
  .site-footer {
    .site-info {
      text-align: left;
      p {
        margin-left: 35%;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .footer-container {
    margin-left: -0px;
    width: 100%;
    ul {
      flex-direction: column;
      text-align: center;
      gap: 5px;
    }

    li {
      margin-right: 0;
    }
    //     }
  }
}
