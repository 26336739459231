@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap");


.container-avatar1 {
  display: flex;
  flex-direction: column;
  margin-left: 25%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
  width: 50%;
  height: auto;
  margin-top: -47%;
  margin-bottom: 5%;
  border: #374151 solid 1px;
  border-radius: 15px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
}

/* Media queries pour les écrans de taille moyenne (tablette) */
@media screen and (min-width: 568px) and (max-width: 780px) {
  .arrow-container {
    display: none;
  }
  .container-avatar {
    width: 90%;
    margin-top: 10px;
    margin-left: 30px;
  }
}

/* Media queries pour les écrans de petite taille (smartphone) */
@media screen and (max-width: 567px) {
  .arrow-container {
    display: none;
  }
  .container-avatar1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px;
  }
}

@media only screen and (max-width: 820px) and (min-width: 481px) {
  .container-avatar1 {
    width: 100%;
    margin: 0;
    margin-top: 40px;
  }
}
