.bold {
    font-weight: 600;
    text-transform: uppercase;
  }
  .couleurs{
     
  .couleurs-container {
  //   position: absolute;
  //   width:100%;
  //   height: 100%;
  margin-top: -35%;
  background: rgba(226,226,226,1);;
      margin-left: 25%;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
      width: 70%;
      height: auto;
      margin-bottom: 5%;
      border: #374151 solid 1px;
      border-radius: 15px;
      align-items: center;
      justify-content: center;
    header {
      position: relative;
      width: 100%;
      text-align: center;
      margin-bottom: 8px;
  
      > div {
        font-size: 15px;
        width: 324px;
        text-align: center;
        margin: 0 auto;
      }
    }
  
    footer {
      width: 360px;
      position: relative;
      margin: 0 auto;
      padding: 10px 4px;
      margin-top: 10px;
      
      .score {
        justify-content: center;
        display: flex;
  
        div {
          padding: 8px
        }
      }
  
      .restart {
        display: flex;
        justify-content: center
      }
    }
  
    .couleurs-content {
      border: 1px solid #DEDEDE;
      padding: 12px;
      box-shadow: 0 0 4px 4px #DEDEDE; 
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(4, 1fr);
      justify-items: center;
      align-items: stretch;
      gap: 1rem;
      margin: 0 auto;
      width: 360px;
      height: 300px;
      perspective: 100%;
      max-width: 720px;
    }
  }}

  .container-footer {
    position: absolute;
  }

  @media only screen and (max-width: 480px) {
    .couleurs{
      .couleurs-container{width: 100%;
      margin: 0;
    margin-top: 10px;
    }
      
    }
  }

  @media only screen and (max-width: 820px) and (min-width: 481px){
    .couleurs{
    .couleurs-container{
      width: 100%;
      margin: 0;
      margin-top: 40px;
     
     }}
  
  }

  @media only screen and (max-width: 835px) {
    .couleurs{
      .couleurs-container{
        width: 100%;
        margin: 0;
        margin-top: 40px;
       
       }}
  }