.rgpd-container {overflow-x: hidden;
    
    .rgpd-content{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
    width: 95%;
    height: auto;
    margin-top: 2%;
    
    margin-left: 45px;
    margin-bottom: 5%;
    justify-content: center;
    border: #374151 solid 1px;
    border-radius: 15px;

    h1{text-align: center;
   }
    
    p {
        font-size: 1rem;
        font-family: alto, sans-serif;
       text-align: justify;
       justify-content: space-between;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
      }
      .text-container {
        margin-left: 10px;
        margin-right: 10px;
      }
      form{
        margin-left: 40px;
       label{display: block;
    font-weight: 600;}
        button{background: #dde1e7;
        margin-bottom: 20px;}
  
      }
}}

@media screen and (min-width: 279px) and (max-width: 567px) {

  .rgpd-container{
   
   margin-left:-50px;
height: auto;

   }
   
}