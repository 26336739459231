.twitter-container {
  background: rgba(226, 226, 226, 1);
  margin-left: 25%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
  width: 70%;
  height: auto;
  margin-top: -35%;
  margin-bottom: 5%;
  border: #374151 solid 1px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  h1 {
    text-align: center;
    margin-top: 20px;
  }
  img {
    width: 150px;
    margin-left: 40%;
    margin-top: -4%;
  }
  p,
  h3 {
    margin: 15px;
  }
}


@media only screen and (max-width: 480px) {
.twitter-container{
  width: 100%;
  margin: 0;
  margin-top: 50px;
}

img{

}
}

@media only screen and (max-width: 820px) and (min-width: 481px){

  .twitter-container{
    width: 100%;
    margin: 0;
    margin-top: 40px;
   
   }

}