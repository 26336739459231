
.container-cours-general{
    background-color: rgb(232, 232, 232);
    margin-left: 25%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
    width: 70%;
    height: auto;
    margin-top: -38%;
    margin-bottom: 5%;
    border: #374151 solid 1px;
    border-radius: 15px;
    text-align: center;
    align-items: center;
    justify-content: center;

.content-cours{
    margin-top: 30px;
    button{
        text-decoration: none;
        padding: 10px;
        font-family: arial;
        font-size: 1em;
        color: #FFFFFF;
        background-color: rgba(202, 107, 69, 0.493);
        border: 4px solid #FFFFFF;
        box-shadow: 3px 3px 8px #444444;
        -webkit-box-shadow: 3px 3px 8px #444444;
        -moz-box-shadow: 3px 3px 8px #444444; 
    }
button:hover{
    padding: 10px;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    box-shadow: 1px 1px 4px #777777;
    -webkit-box-shadow: 1px 1px 4px #777777;
    -moz-box-shadow: 1px 1px 4px #777777;
}

}
.content-payant{
  
    button{
        margin-top: 5px;
        text-decoration: none;
        padding: 10px;
        font-family: arial;
        font-size: 1em;
        color: #000000;
        background-color: rgba(69, 202, 96, 0.493);
        border: 4px solid #FFFFFF;
        box-shadow: 3px 3px 8px #444444;
        -webkit-box-shadow: 3px 3px 8px #444444;
        -moz-box-shadow: 3px 3px 8px #444444; 
    }
button:hover{
    padding: 10px;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    box-shadow: 1px 1px 4px #777777;
    -webkit-box-shadow: 1px 1px 4px #777777;
    -moz-box-shadow: 1px 1px 4px #777777;
}
}
}

@media only screen and (max-width: 480px) {

    .container-cours-general{
        width: 100%;
        margin-left: 0;
        margin-top: 0px;
    }
}
@media only screen and (max-width: 820px) and (min-width: 481px){

    .container-cours-general{
      width: 100%;
      margin: 0;
      margin-top: -0px;
     
     }
  }

  