.mouse-simulator {
    position: relative;
    width: 100%; /* Modifier selon vos besoins */
    height: 400px; /* Modifier selon vos besoins */
    background-color: #f0f0f0;
    border: 2px solid #ccc;
    cursor: none;
  }
  
  .mouse-pointer {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #333;
    border-radius: 50%;
  }
  
  .text {
    position: absolute;
    font-size: 16px; /* Taille de police ajustée selon vos besoins */
    cursor: pointer; /* Curseur changeant lorsqu'il est sur le texte */
  }
  .text:hover{
    color: red;
    font-size: 10px;
  }
  