.panel-container {
  text-align: center;
  h1 {
    color: black;
    text-align: center;
    text-align: center;
  }

  .row {
    margin-top: 2%;
    padding-bottom: 20px;

    .col-md-4 {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
