
    .inscription-container {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
        width: 30%;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        border:#374151 solid 1px;
        border-radius: 15px;
        margin-top: 7%;
        margin-bottom:7%;
        padding: 50px;
        font-size: 22px;
        h2 {text-align: center;
            color: #374151;
            font-family: 'spartan_mbsemibold', sans-serif; 
            color: rgba(1, 128, 142, 0.953);
        font-size: 22px;}
    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        input {
            height: 30px;
            margin-bottom: 20px;
            border-radius: 10px;
            border: 1px solid #374151;
            font-size: 16px;
            color: #374151;
          
            }
            button {
                
                height: 40px;
                margin-top: 20px;
                border-radius: 10px;
                border: 1px solid #374151;
                padding: 10px;
                font-size: 16px;
                color: #374151;
                }
                button:hover{
                    background-color: #d2d7df;
                }

    }
    }

    @media only screen and (max-width: 480px) {
.inscription-container{
    width: 100%;
    margin-top: -15%;

}
.footer-container{
    
    margin-top: 250px;
      
   
}
        
    }