
.btnProfil{
    width: 70px;
    height: 42px;
        font-size: 17px;
        margin-left: auto;
       background-color: rgba(247, 244, 243, 0.493);
  color: #f4f3f4;
        margin-top: -6px;
        border-radius: 25px;
        border: 1px solid rgba(202, 107, 69, 0.493) ;
}
.btnProfil:hover{
  color:#dddbdd;
  text-decoration: none;
}

  .loginbar {
  display: flex;
  justify-content: flex-end;
  background-color: rgb(50, 50, 51);
 
  button {
    background-color: rgb(50, 50, 51);
    color: azure;
    cursor: pointer;
  }
  
a {text-decoration: none;}

.reseauxSociaux{
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: 10px;
  > *:not(:last-child) {
    margin-right: 10px; /* Espacement entre les éléments sauf le dernier */
  }
  .logoyoutube,
.logofacebook,
.logodiscord {
  width: 24px;
  
}
}
}
.container-nav {
  
  .entrez a{
    font-size: xx-large;
    font-weight: 700;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-decoration: none;
  
    
  }
  .entrez a:hover{
    color:#ff6347 ;
    font-size: 40px;
    
  }
  width: 100%;
  justify-content: space-around;
   background-color: rgba(1, 22, 142, 0.21);
   padding-bottom: 75px;
nav{
  background-color: rgb(202,107,69);
  border: solid 5px white;
  border-radius: 25px;
  width: auto;
  height: 50px;
  margin-top: 15px;
}
  ul{
   
    justify-content: space-around;
    
  }
ul li{
  list-style: none;
  
}
ul li a {
  text-decoration: none; /* Supprimer le souligné */
  
  
}

  a{
  font-size: 20px;
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
 }
  .logo {
    display: flex;
    

    .imglogo {
      width: 15%;
      position: absolute;
      margin-top: 8px;
      border: solid 2px white;
      border-radius: 50px;
      z-index: 0;
    }

  }

  .menu {
    display: flex;
    width: 100%;
    cursor: pointer;
    list-style: none;
    margin-top: 5px;
    text-transform: uppercase;
    font-family: alto, sans-serif;
    justify-content: space-between;
    padding: 0;
    li {
      margin-right: 10px;
      padding:5px 15px;
      height: auto;
      white-space: nowrap;
width: 100%;
      a {
        
        font-size: 16px;
        color: #0a0a0a;
        text-decoration: none;
        
      }

      &:not(:last-child) {
        margin-right: 30px;
       
      } 
       a:hover{
    color:#dddbdd;
   }
    }
  
  }

  .burger-menu {
    display: none;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    
  }

  @media screen and (max-width: 1114px) {
    flex-direction: column;

    .logo {
      margin-top: 0%;
      margin-left: 45%;

      .imglogo {
        margin-top: 0%;
        width: 15%;
      }
    }

    .list-nav ul {
      flex-direction: column;
      align-items: center;
      padding: 0;
    }

    .list-nav li {
      margin: 10px 0;
    }

    .menu {
      display: none;
    }

    .burger-menu {
      display: block;
      z-index: 999;
    }

    .container-nav {
      flex-direction: column;
      align-items: center;
    }

    .menu.show {
      display: flex;
      flex-direction: column;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: rgba(248, 248, 248, 0.99);
      color: #454545;
    }

    .menu li {
      margin: 0;
      text-align: left;
    }

    .burger-menu.active {
      color: #bdbbbb;
    }
  }
.entrez a{
  font-size: xx-large;
  font-weight: 700;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-decoration: none;

  
}
.entrez a:hover{
  color:#ff6347 ;
  font-size: 40px;
  
}


  @media screen and (min-width: 279px) and (max-width: 567px) {
    flex-direction: column;
    .container-nav .menu li a {
      font-size: 16px;
      color: #0a090a;
      text-decoration: none;
  }
    .container-nav {margin-top: 100px;}
    .burger-menu{
    margin-left: 10px;
    z-index: 5000;
  }
.entrez {
    margin-top: 25px;
  }
    .logo {
      margin-top: 0%;
      margin-left: 124px;
      .imglogo {
      margin-top: 3%;
        width: 60%;
      }
    }

    .list-nav ul {
      flex-direction: column;
      align-items: center;
      padding: 0;
      margin-top: 100px;
      
    }

    .list-nav li {
      margin: 10px 0;
    }
  }
}

.scrolled {
  background-color: rgba(65, 68, 69, 0.45);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.login {
  display: flex;
  width: 100%;
  cursor: pointer;
  list-style: none;
   margin-top: -12px;
  text-transform: uppercase;
  font-family: alto, sans-serif;
  font-size: 22px;
    

    .ulCo {
      
      background:rgba(4, 249, 151, 0.975);
       border-radius: 25px;
       padding: 10px;
       height: 30px;
       display: flex;
       margin-left: 500px; 
      width: 100%;
      margin-top: -40px;
       list-style-type: none;
     li {
       transition: background-position-x 0.9s linear;
       height: 50px;
      
       a {
        font-size: 16px;
         color: #4e4d4d;
         text-decoration: none;
         transition: all 0.45s;

        &:hover {
          color: #f4f3f4;
           border: #454545 1px solid;
           font-weight: bold;
           padding: 1px;
         }
      }

       &{
         margin-right: 60px;
         margin-top: -10px;
       }
     }
  }
     
  }