.abon-container {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
  background-color: #fff;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  border: #374151 solid 1px;
  border-radius: 15px;
  margin-top: 67px;
  margin-bottom: 67px;
  padding: 50px;
  scroll-behavior: smooth;
  .ligne {
    width: 50%;
    margin-left: 25%;
  }
  h1,
  h4 {
    text-align: center;
  }

  .boutons {
    display: flex;
    text-align: center;
    border: #374151 solid 2px;
    height: auto;
  }

  .btnA {
    text-align: center;
    background-color: rgb(247, 235, 235);
    width: 50%;
  }
  .btnA:hover {
    background-color: #ffc8a3;
  }

  .btnB {
    background-color: transparent;
    width: 50%;
  }
  .btnB:hover {
    background-color: #ffc8a3;
  }
  button:hover {
    background-color: rgb(254, 254, 254);
  }
  p {
    text-align: center;
    font-weight: 600;
    margin-left: 10px;
  }
  .type-sub {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    border: #374151 solid 1px;
    border-radius: 15px;
    margin-top: 67px;
    margin-bottom: 67px;
    padding: 50px;
    h3 {
      text-align: center;
    }
    p {
      color: cadetblue;
      font-size: 22px;
    }
  }
  .type-sub:hover {
    background-color: #ffc8a3;
  }
  .btnpaypal {
    width: 100%;
    margin-left: 5%;
  }
}

@media only screen and (max-width: 820px) and (min-width: 481px) {
  .abon-container {
    width: 100%;
    margin: 0;
    margin-top: -0px;
    z-index: -1000;
    margin-top: 100px;
  }
}

@media only screen and (max-width: 480px) {
  .abon-container {
    width: 100%;
    margin-top: 10%;
    margin-left: 0%;
    .type-selector {
      width: 352px;
      margin-left: -48px;
    }
  }
}
