.insta-container{
    background: rgba(226,226,226,1);;
    margin-left: 25%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
    width: 70%;
    height: auto;
    margin-top: -28%;
    margin-bottom: 5%;
    border: #374151 solid 1px;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    h1{text-align: center;}
   img{width: 200px;
margin-left: 40%;
margin-top: -10%;}
p, h3{
    margin: 15px;
}
}

@media only screen and (max-width: 480px) {
.insta-container{
    width: 100%;
    margin: 0;
    margin-top: 50px;
}

}

@media only screen and (max-width: 820px) and (min-width: 481px){

    .insta-container{
      width: 100%;
      margin: 0;
      margin-top: 100px;
     
     }
  
  }