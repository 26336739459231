.loterie-container {
  background-color: #FFFff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
  width: 70%;
  height: auto;
  margin-bottom: 5%;
  justify-content: center;
  border: #374151 solid 1px;
  border-radius: 15px;
  margin-left: 25%;
  margin-top: -70%;
  text-align: center;

  .loterie-content {
    margin-top: 15px;
    input {
      display: flex;
      margin-left: 50%;
      margin-top: 15px;
    }
    label {
      display: flex;
      margin-left: 40%;
    }
    button {
      display: flex;
      margin-top: 20px;
      margin-left: 50%;
    }
    button:hover {
      cursor: pointer;
      background-color: rgba(220, 220, 220, 0.889);
    }
  }
  .loterie-resultat {
    align-items: center;
    justify-content: space-around;
    padding: 10px;

    ul {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    li {
      font-size: 28px;
      font-weight: bold;
      color: #0346c4;
      border-bottom: 2px black solid;
    }
    .lottery-numbers {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .complémentaire {
      p {
        font-size: 28px;
        font-weight: bold;
        color: #f90b03;
        border-bottom: 2px black solid;
      }
    }
  }
}
