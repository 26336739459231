.presentation {
  width: 70%;
  background-color: #fff;
  margin-left: 25%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
  height: auto;
  margin-top: -35%;
  margin-bottom: 5%;
  border: #374151 solid 1px;
  border-radius: 15px;
  text-align: center;
  align-items: center;
  justify-content: center;

  .present-content {
    h3 {
      margin: 15px;
      text-align: center;
    }
    p {
      margin: 15px;
      text-justify: auto;
    }
  }
}

@media only screen and (max-width: 480px) {
  .presentation {
    width: 100%;
    margin-top: 10%;
    margin-left: 0%;
  }
}

@media only screen and (max-width: 820px) and (min-width: 481px) {
  .presentation {
    width: 100%;
    margin: 0;
    margin-top: -0px;
  }
}
