.articles-container {
  display: flex;
  margin-left: 25%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
  width: 50%;
  height: auto;
  margin-top: -60%;
  margin-bottom: 5%;
  border: #374151 solid 1px;
  border-radius: 15px;
  text-align: center;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  .articles-content {
    text-align: center;
    margin-right: 100%;
  }
  .arrow-container4 {
    position: relative;
    cursor: pointer;
  }

  .arrow-down4 {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 30px solid #3498db; /* Couleur de la flèche */
    transition: transform 0.3s ease-in-out;
    margin-top: -130px;
    margin-left: 820px;
  }

  .arrow-container4:hover .arrow-down4 {
    transform: translateY(5px); /* Effet de descente lors du survol */
  }
}
