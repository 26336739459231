.footerAcc-container {
    display: flex;
    
  flex-direction: column; /* Change la direction de la disposition en colonne sur des écrans plus petits */
  justify-content: center;
  align-items: center;
  // background-color: rgba(1, 22, 142, 0.21);
  // padding: 20px;
 margin-top: auto;
 margin-left: 30%;
 bottom: -450px;
 width: 50%;

.footerAcc-nav{
  margin-top: 20px;
  background-color: rgb(202,107,69);
  border: 5px solid white;
  border-radius: 25px;
  padding: 10px;
    ul{
        list-style: none; 
        display: flex;
        gap: 10px; 
        padding: 0;
        margin:0;
    }
    
     li{
        margin-right: 10px;
        
    }
    li:last-child {
        margin-right: 0; 
        list-style-type: none;
      }
      a {
        text-decoration: none; 
        color: rgb(250, 250, 250);}
 
          .logo-letter {
            font-size: 24px;
            font-weight: bold;
          }
          
          .site-info {
            text-align: left;
          }
          
          .site-info p {
            margin: 0;
          }}
}

@media only screen and (max-width: 768px) {
    .footerAcc-container {
      ul {
        flex-direction: column; 
        text-align: center; 
        gap: 5px; 
      }
  
      li {
        margin-right: 0;
      }
    }
  }