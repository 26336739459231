/* styles.css */

/* Conteneur principal de la page de chat */
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Ajustez la hauteur en fonction de vos besoins */
  background: #FFFff;
  font-family: alto, sans-serif;
  width: 70%;

  margin-left: 25%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
  height: auto;
  margin-top: -65%;
  margin-bottom: 5%;
  border: #374151 solid 1px;
  border-radius: 15px;

  justify-content: center;
  .chat-content {
    width: 100%;
  }
}

/* Conteneur des messages */
.message-list-container {
  flex: 1; /* Le conteneur des messages prendra toute la hauteur restante */
  overflow-y: auto; /* Activation du défilement vertical si nécessaire */
}

/* Liste des messages */
.message-list {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  li {
    font-size: 40px;
  }
}

/* Conteneur de la zone de saisie de message et des boutons */
.message-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f2f2f2;
  .buttonEmo {
    border: none;
  }
  img {
    width: 20px;
  }
  textarea {
    width: 400px;
    height: 100px;
  }
}

/* Zone de saisie de message */
.message-input {
  flex: 1; /* La zone de saisie de message prendra autant d'espace que possible */
  margin-right: 10px; /* Espacement avec les boutons */
}

/* Bouton pour envoyer un message */
.send-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Bouton pour afficher le sélecteur d'emojis */
.emoji-button {
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px; /* Espacement avec le bouton pour démarrer la vidéo */
}

/* Bouton pour démarrer la vidéo */
.video-button {
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Conteneur principal de la fenêtre de chat */
.chat-window {
  position: relative; /* Permet d'utiliser position:absolute dans les enfants */
  height: 100vh; /* Ajustez la hauteur en fonction de vos besoins */
  display: flex;
  flex-direction: column;
}

.messages-container {
  background: rgb(255, 255, 255);
  min-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 60%;
  justify-content: flex-end; /* Alignement des messages en bas */
  align-items: flex-start; /* Alignement des messages à gauche */
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  .messages-content {
    margin-bottom: 10px;
    max-width: 80%; /* Limite la largeur des messages à 80% de la largeur parente */
    word-wrap: break-word; /* Permet aux mots longs de passer à la ligne */
  }
}
