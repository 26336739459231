.card {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 2px 2px 4px 4px #DEDEDE;
    transition: 0.3s;
    transform-style: preserve-3d;
    position: relative;
    cursor: pointer;
    
    img {
      width: 95%;
      height: 95%;
    }
    
    .card-face {
      backface-visibility: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      &.card-back-face {
        transform: rotateY(180deg);
      }
    }
  
    &.is-flipped {
      transform: rotateY(180deg);
    }
  
    &.is-inactive {
      // visibility: hidden;
      opacity: 0;
    }
  }