.form-container{
    border: 1px solid gray;
   background-color: beige;
   display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
   .contact-form{
    text-align: center;
    input{margin-right: 30%;}
    .input-group {
        margin-bottom: 20px;
      }
      
      .label {
        font-weight: bold;
        margin-bottom: 5px;
      }
      
      .input-field{
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-sizing: border-box;
      }
   
}}