.citation-container {
  background-color: #FFFff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.992);
  width: 70%;
  height: auto;
  margin-bottom: 5%;
  justify-content: center;
  border: #374151 solid 1px;
  border-radius: 15px;
  margin-left: 25%;
  margin-top: -55%;
  text-align: center;
  p {
    border: 2px solid hwb(113 45% 23%);
    background-color: aliceblue;
  }
}
