/************ Banniere ******************/
.bodyAccueil {
  background: #FFFF;
  font-family: alto, sans-serif;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  h1,
  h2,
  h3 {
    font-family: Poppins;
    color: rgb(4, 7, 7);
    text-align: center;
  }

  p {
    font-family: alto;
  }
  // .logoyoutube {width: 1.5%;margin-right: 90%;margin-top:-200px; z-index: 10;}
  // .logofacebook {width: 1.5%;margin-right: 85%;margin-top: -250px;}
  // .logodiscord {width: 1.5%;margin-right: 80%;margin-top: -295px;}
  .banniere-container {
    width: 100%;
    background-image: url("../media/bandeauYoutube.jpg");
    background-attachment: fixed;
    background-size: cover; /* Ajuste la taille de l'image pour remplir l'élément */
    background-position: center; /* Centre l'image horizontalement et verticalement */
    background-repeat: no-repeat; /* Empêche la répétition de l'image */
    // background-color: rgba(1, 128, 142, 0.248);
    margin-top: 40px;
    opacity: 0.8;
    filter: brightness(0.9);
  }
  .banniere-content {
    height: 530px;
    display: flex;
    justify-content: space-between; /* Pour espacer les éléments */
    align-items: center; /* Pour aligner les éléments verticalement */
    .banniere-text {
      text-align: justify;
      margin-left: 5%;
      margin-right: 5%;
      margin-top: 55px;
      color: #edeceb;
      font-size: 2.2vw;
      font-weight: 900;
      font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
      span {
        color: #007bffa4;
      }
      h1,
      h2,
      h3 {
        font-family: Poppins;
        color: #eeeeee;
        text-align: center;
        font-weight: 900;
      }
      p {
        font-family: alto, sans-serif;
      }
    }

    .imgbanniere {
      width: 40%;
      height: 530px;
    }
  }
  button {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  /************ Text-anime ******************/

  /************ Summary-pub ******************/
  .summary-pub {
    justify-content: space-between;
    padding-top: 50px;
    transition: all 0.3s ease;
  }

  //   .summary-pub:hover {
  //     transform: scale(1.05);
  //     box-shadow: 0 0 10px rgba(0,0,0,.1);
  //   }

  //****** Message ******//
  .summary-container {
    font-size: large;
    justify-content: space-between;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
  }
  .summary-container:hover {
    background-color: #8a9f113b;
  }
  .summary-container .text {
    overflow: hidden;
    height: auto;
  }

  .summary-container .message {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;

    justify-content: center;
    align-items: center;
  }

  .summary-container .message img {
    width: 150px;
  }

  .summary-pub .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .summary-pub .modal-content {
    background-color: rgba(226, 226, 229, 0.622);
    text-align: justify;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
    width: 50%;
  }

  .summary-pub .modal-content .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    padding: 5px;
    background-color: white;
    border: #f5f5f5 solid 1px;
    border-radius: 20%;
  }

  .summary-pub .modal-content .summary-text {
    text-align: justify;
    padding: 10px;
    font-weight: 900;
    line-height: 1.5;
    font-family: "alto", sans-serif;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 50px;
    color: #060606e5;
  }

  p {
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    .summary-container {
      width: 77%;
    }
  }

  //****** Section TEMOIGNAGES ******//

  .testimonials {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-align: center;
  }
  .testimonial-heading {
    margin-bottom: 20px;
  }

  .testimonial-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin-top: 50px;

    .testimonial {
      background-color: rgba(1, 22, 142, 0.375);
      font-size: large;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;
      flex-basis: 45%;
      max-width: 400px;
      transition: all 0.3s ease;

      .testimonial-avatar {
        width: 40px; /* Ajustez la taille de l'avatar selon vos besoins */
        height: 40px; /* Ajustez la taille de l'avatar selon vos besoins */
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 4px 0 8px rgba(0, 0, 0, 0.2); /* Ajustez la couleur et l'intensité de l'ombre selon vos besoins */
      }

      .testimonial-avatar img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .testimonial:hover {
      transform: scale(1.05);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    .testimonial-text {
      font-size: 1.1rem;
      line-height: 1.5;
      color: #333;
      font-family: alto, sans-serif;
    }

    .testimonial-author {
      font-size: 1rem;
      font-style: italic;
      color: #333;
    }
  }
  @media only screen and (max-width: 768px) {
    .testimonial-container {
      display: flex;
      flex-direction: column;
    }
  }

  //******Section FAQ ******//
  .faq-section {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../media/sourisPc.jpg");
    background-size: cover;
    opacity: 0.7;
    height: auto;
    padding: 40px 0;
    border-radius: 1px solid black;
    text-align: center;

    .faq-item {
      // background-color: rgba(1, 128, 142, 0.248);

      padding: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      border-radius: 5px;

      .faq-question {
        font-size: 1.5rem;
        font-weight: 900;
        color: #000000;
        margin-bottom: 10px;
        font-family: alto, sans-serif;
      }
      .faq-answer {
        margin-left: 55px;
        font-size: 1rem;
        font-weight: 900;
        color: #000000;
        display: none;
        font-family: alto, sans-serif;
      }

      .faq-item.active .faq-answer {
        display: block;
      }
    }

    .faq-heading {
      text-align: center;
      font-size: 2rem;
      color: #333;
      margin-bottom: 20px;
    }

    .visible {
      display: block;
    }

    .faq-answer.visible {
      display: block; /* Rendre les réponses visibles lorsque la classe "visible" est appliquée */
      width: 80%;
      margin-left: 10%;
      font-size: 1.1rem;
      color: rgb(0, 0, 15);

      p {
        text-align: center;
      }
    }
    /* Pour le bouton qui permet de basculer entre l'affichage et non affichage des questions/réponses*/
    .faq-toggle {
      margin-top: -10px;
      cursor: pointer;
      background-color: rgb(228, 243, 248);
      border-radius: 50%;
    }
    .faq-toggle:hover {
      background-color: rgb(120, 122, 120);
    }
  }

  /************ Section Diplomes ************/
  .certifications-section {
    background-color: #77777742;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    background-image: url("../../src/media/diplome2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    border-top-color: red;
    h3,
    p,
    h2 {
      text-align: Left;
      font-family: alto, sans-serif;
    }
    .span {
      color: #ffff;
    }
  }
}

/* ************ Responsive ************ */
@media screen and (max-width: 567px) {
  .bodyAccueil {
    .banniere-container {
      width: 100%;
      height: 150px;
      background-image: url("../media/bandeauYoutube.jpg");
      background-size: cover; /* Ajuste la taille de l'image pour remplir l'élément */
      background-position: center; /* Centre l'image horizontalement et verticalement */
      background-repeat: no-repeat;
      display: none;
      .banniere-text {
        margin-top: -360px;
        font-size: 14px;
      }
    }
  }
}
